import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import sideImage from "../../../assets/images/Group 426.png";
import classnames from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import Footer from "../../Components/Footer";
import { Post, Get } from "../../../utilities/axiosRequest";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

import ToastBox from "../../Components/ToastBox/ToastBox";
const validationSchema = Yup.object().shape({
  Username: Yup.string().required("Username is required"),
  password: Yup.string()
    .trim("No white space allow in starting")
    .required("Password is required"),
});

class DeleteAccount extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  state = {
    showPassword: false,
    show: false,
    message: "",
  };

  deleteAccountHandler = (values) => {
    const data = {
      username: values.Username,
      password: values.password,
    };
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post("https://api.niddaa.com/api/v1/user/login", data, options)
      .then((result) => {
        if (result.data.accessToken === undefined) {
          this.setState({
            message:
              i18next.language === "en"
                ? "Invalid Username or Password"
                : "اسم المستخدم أو رقم المرور خطأ",
          });
        } else {
          this.setShow(true, "Account Deleted Successfully");
        }
      })
      .catch((error) => {
        this.setShow(true, error.response?.data?.message || error.message);
      });
  };

  gettingData = (values) => {
    console.log(values);
    this.setState((prev) => ({
      ...prev,
      ...values,
    }));
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  changeUsername = () => {};

  setShow = (status, message) => {
    this.setState({ show: status, message });
  };
  handleChangeText = (e) => {
    console.log(e);
    this.setState({ Username: e.target.value });
    //this.props.onChange(e);
  };

  render() {
    const { t } = this.props;
    const { showPassword, message, show } = this.state;
    return (
      <>
        <ToastBox show={show} message={message} setShow={this.setShow} />

        <Container>
          <Row className="loginPage">
            <Col md="6" className="align-self-center">
              <h3 style={{ color: "darknavy", textAlign: "center" }}>
                Delete Account
              </h3>
              {message !== "" && (
                <p className="m-0 text-muted text-center text-dark-red">
                  {message}
                </p>
              )}
              <Formik
                initialValues={{
                  Username: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => this.deleteAccountHandler(values)}
                onKeydown={(values) => this.deleteAccountHandler(values)}
              >
                {({
                  errors,
                  values,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  return (
                    <form>
                      <Row className="mt-5">
                        <Col xs="12" className="mb-4">
                          {/**  <Label className="d-flex">{t('Username')}</Label>**/}
                          <FormGroup>
                            <Input
                              invalid={errors.Username && touched.Username}
                              type="text"
                              value={values.Username}
                              name="Username"
                              onChange={handleChange("Username")}
                              onBlur={handleBlur("Username")}
                              placeholder={"Username"}
                              className="BoxShadow pl-4"
                            />
                            <FormFeedback>
                              {touched.Username && errors.Username}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="12">
                          {/**  <Label className="d-flex">{t('Password')}</Label>**/}
                          <FormGroup>
                            <InputGroup
                              className={classnames("BoxShadow", {
                                "select-element is-invalid":
                                  errors.password && touched.password,
                              })}
                            >
                              <Input
                                invalid={errors.password && touched.password}
                                autoComplete="off"
                                type={showPassword ? "text" : "password"}
                                value={values.password.trim()}
                                name="password"
                                onChange={handleChange("password")}
                                onBlur={handleBlur("password")}
                                placeholder={t("Password")}
                                className="pl-4 border-0 shadow-none"
                              />
                              <i
                                className={classnames(
                                  "passwordHideShow d-flex align-items-center pr-4",
                                  {
                                    "fa fa-eye-slash": !showPassword,
                                    "fa fa-eye": showPassword,
                                  }
                                )}
                                onClick={() =>
                                  this.setState((prev) => ({
                                    showPassword: !prev.showPassword,
                                  }))
                                }
                              ></i>
                            </InputGroup>
                            <FormFeedback>
                              {touched.password && errors.password}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="12" className="text-center mt-3">
                          <Button
                            className="loginBtn px-4 font-weight-bolder"
                            onKeyPress={handleSubmit}
                            onClick={handleSubmit}
                          >
                            {t("Delete Account")}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </Col>
            <Col md="6" className="d-none d-md-block align-self-center">
              <img src={sideImage} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}

export default withTranslation()(withCookies(DeleteAccount));
