import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TrackOrder from "./Container/Pages/TrackOrder/TrackOrder";
import JoinToWork from "./Container/Pages/JoinToWork/JoinToWork";
import MyProfile from "./Container/Pages/TrackOrder/TrackOrder";
import Maintenance from "./Container/Pages/Maintenance/Maintenance";
import Home from "./Container/Pages/Home/Home";
//import FirstPage from "./Container/Pages/Home/FirstPage";
import CompanyHome from "./Container/Pages/CompanyPages/ListMaintanances";
import AdminReports from "./Container/Pages/AdminPages/AdminReports";
import SignUp from "./Container/Pages/SignUp";
import EditProfile from "./Container/Pages/SignUp/MyProfile";
import ResetPassword from "./Container/Pages/SignUp/ResetPassword";
import Dashboard from "./Container/Pages/CompanyPages/Dashboard";
import AdminDashboard from "./Container/Pages/AdminPages/AdminDashboard";
import DriverDashboard from "./Container/Pages/DriverPages/Dashboard";
import Login from "./Container/Pages/Login/Login";
import Privacy from "./Container/Pages/Login/Privacy";
import DeleteAccount from "./Container/Pages/DeleteAccount/DeleteAccount";
export default function Routes(props) {
  console.log("props..in router", props);
  return (
    <Switch>
      <Route path="/" render={() => <Home sellanguage={props.selLanguage} />} />
      <Route path="/delete-account" component={DeleteAccount} />
      <Route path="/login" component={Login} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/trackorder" component={TrackOrder} />
      <Route path="/my-profile" component={EditProfile} />
      <Route path="/reset-password/:rtoken" component={ResetPassword} />
      <Route path="/works" component={JoinToWork} />
      <Route path="/signup" component={SignUp} />
      <Route path="/maintenance" component={Maintenance} />
      <Route
        path="/terms"
        render={() => (
          <h3 style={{ textAlign: "center", color: "#FF5900" }}>
            Coming Soon....
          </h3>
        )}
      />
      <Route
        path="/contactus"
        render={() => (
          <h3 style={{ textAlign: "center", color: "#FF5900" }}>
            Coming Soon....
          </h3>
        )}
      />
      <Route
        path="/aboutus"
        render={() => (
          <h3 style={{ textAlign: "center", color: "#FF5900" }}>
            Coming Soon....
          </h3>
        )}
      />
      <Route path="/company-home" component={Dashboard} />
      <Route path="/driver-home" component={DriverDashboard} />
      <Route path="/pending-requests" component={Dashboard} />
      <Route path="/company/profile/" component={Dashboard} />
      <Route path="/company/complaints/" component={Dashboard} />
      <Route path="/driver/profile/" component={DriverDashboard} />
      <Route path="/driver/pending-requests" component={DriverDashboard} />
      <Route path="/admin-home" component={AdminDashboard} />
      <Route path="/admin/reports" component={AdminReports} />
      <Route path="/admin/maintenance" component={Maintenance} />
      {/** <Redirect to="/" />**/}
    </Switch>
  );
}
